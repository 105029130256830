import * as React from 'react'
import injectSheet from 'react-jss'
import HQTheme from '../../theme/theme'
import classNames from 'classnames'
import { MdPlayArrow } from 'react-icons/md'
import Boundaries from '../../components/Boundaries/Boundaries'
import FormDialog from '../../components/Dialog/Dialog'
import CircleAnimation from '../../components/Animation/Circles'
import TrustedCompanies from '../../components/SocialProof/TrustedCompanies'
import RegisterWithEmail from '../../components/RegisterWithEmail';

const headerHeight = 80
const trustedCompaniesHeight = 160
const borderHeight = 1

const styles = {
  splash: {
    height: `100vh`,
    position: 'relative',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '& > div:nth-child(2)': {
      height: `calc(100% - ${headerHeight}px - ${trustedCompaniesHeight}px - ${borderHeight}px)`,
      '@media (max-width: 1300px)': {
        height: `calc(100% - ${headerHeight}px)`,
      },
    },
  },
  bg: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    '& > div:first-of-type': {
      height: '100%',
      '& > picture > img': {
        zIndex: '-1',
      },
    },
  },
  splashContent: {
    height: `calc(100vh - ${headerHeight}px - ${trustedCompaniesHeight}px)`,
    marginTop: '80px',
    '@media (max-width: 1024px)': {
      height: `calc(100vh - ${headerHeight}px)`,
    },
  },
  infoBlock: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: HQTheme.palette.text.secondaryContrast,
    height: '100%',
    '& > div': {
      width: '100%',
    },
    '@media (max-width: 1300px)': {
      '& > div': {
        width: 'auto',
      },
      '& > div:first-of-type': {
        width: '100%',
      },
    },
    '& > div:first-of-type': {
      zIndex: '1',
      '& > h2': {
        margin: '0',
        fontSize: '3.6rem',
        fontWeight: '500',
        '@media (max-width: 1024px)': {
          fontSize: '5.6rem',
        },
        '@media (max-width: 767px)': {
          fontSize: '3.6rem',
        },
      },
      '@media (max-width: 1024px)': {
        textAlign: 'center',
        width: '100%',
      },
    },
    '& > div:last-of-type': {
      marginLeft: '12px',
      '& > video': {
        width: '100%',
        '@media (max-width: 1300px)': {
          position: 'absolute',
          top: 'calc(50% - 420px)',
          left: '40%',
          opacity: '0.3',
          height: '900px',
        },
        '@media (max-width: 1024px)': {
          left: 'calc(50% - 250px)',
          top: 'auto',
          bottom: 'calc(25% - 170px)',
          opacity: '0.3',
          width: '500px',
          height: 'auto',
        },
        '@media (max-width: 767px)': {
          bottom: '0',
          opacity: '0.1',
          width: 'auto',
        },
      },
    },
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      position: 'relative',
    },
  },
  text: {
    margin: '48px 0',
    fontSize: '1.3rem',
    lineHeight: '1.5',
    fontWeight: '300',
  },
  highlight: {
    color: HQTheme.palette.text.primaryContrast,
  },
  textHighlight: {
    color: HQTheme.palette.text.primaryContrast,
    fontWeight: '700',
  },
  buttonContainer: {
    height: '48px',
    lineHeight: '48px',
    '@media (max-width: 1024px)': {
      textAlign: 'center',
      width: '100%',
    },
    '@media (max-width: 400px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  button: {
    boxSizing: 'border-box',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    border: `1px solid ${HQTheme.palette.secondary.main}`,
    cursor: 'pointer',
    fontSize: '1.1rem',
    textDecoration: 'none',
    color: HQTheme.palette.text.secondaryContrast,
    borderRadius: '4px',
    width: 'auto',
    margin: 'auto',
    padding: '8px 16px',
    height: '100%',
    display: 'inline-block',
    lineHeight: '32px',
    '&:hover': {
      border: `1px solid ${HQTheme.palette.primary.main}`,
      color: HQTheme.palette.text.primaryContrast,
      '& > svg': {
        color: HQTheme.palette.text.primaryContrast,
      },
    },
    '&.-signup': {
      marginRight: '16px',
      backgroundColor: HQTheme.palette.secondary.main,
      color: HQTheme.palette.text.primary,
    },
    '&.-signup:hover': {
      backgroundColor: HQTheme.palette.primary.main,
      border: `1px solid ${HQTheme.palette.primary.main}`,
    },
    '@media (max-width: 767px)': {
      fontSize: '1rem',
    },
    '@media (max-width: 400px)': {
      display: 'flex',
      alignItems: 'center',
      '&.-signup': {
        margin: '0',
      },
      '&:not(.-signup)': {
        textDecoration: 'underline',
        border: 'none',
      },
    },
  },
  playIcon: {
    verticalAlign: 'middle',
    color: HQTheme.palette.primary.main,
    marginRight: '12px',
    '@media (max-width: 400px)': {
      display: 'none',
    },
  },
  video: {
    width: '746px',
    height: '420px',
    '@media (max-width: 676px)': {
      width: '320px',
      height: '180px',
    },
  },
  registerWithEmail: {
    maxWidth: '450px',
    marginTop: '32px',

    "@media (max-width: 1024px)": {
      margin: '32px auto 0 auto'
    }
  }
}

interface SplashProps {
  classes?: any
  location?: any
  scrollDirection?: string
}

interface SplashState {
  menuOpen?: boolean
  playVideo?: boolean
}

class Splash extends React.Component<SplashProps, SplashState> {
  state = {
    menuOpen: false,
    playVideo: false,
  }
  getActiveSlug() {
    const { location } = this.props

    if (!!location) {
      return location.pathname.substring(1).split('/')[0]
    }
  }
  onMenuClick = () => {
    this.setState(prevState => {
      return { menuOpen: !prevState.menuOpen }
    })
  }
  renderVideo = () => {
    const { classes: c } = this.props
    return (
      <iframe
        className={c.video}
        src="https://player.vimeo.com/video/309315899?&muted=1&autoplay=1"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
      />
    )
  }
  openVideo = () => {
    this.setState({ playVideo: true })
  }
  closeVideo = () => {
    this.setState({ playVideo: false })
  }
  render() {
    const { classes: c } = this.props
    return (
      <div className={c.splash}>
        <Boundaries>
          <div className={c.splashContent}>
            <div className={c.infoBlock}>
              <div>
                <h2>
                  Get <span className={c.highlight}>Your Shit</span> Together
                </h2>
                <div className={c.text}>
                  <p>
                    <span className={c.textHighlight}>Central access to information </span> 
                    from hundreds of sources. Connect your <br/> Dropbox, Google, Office365, Slack and 
                    <span className={c.textHighlight}> all other supported apps </span> 
                    to save time, <br/>share knowledge and strengthen client relationships.
                  </p>
                </div>
                <div className={c.buttonContainer}>
                  <a href="https://hq.app/app" className={classNames(c.button, '-signup')}>
                    Get more done, today
                  </a>
                  <div onClick={this.openVideo} className={c.button}>
                    <MdPlayArrow className={c.playIcon} size={24} />
                    <span>Learn more</span>
                  </div>
                </div>
                <div className={c.registerWithEmail}>
                  <RegisterWithEmail />
                </div>
              </div>
              <div>
                <CircleAnimation />
              </div>
            </div>
          </div>
          <TrustedCompanies color={'white'} hideOnTablet />
        </Boundaries>
        {!!this.state.playVideo && (
          <FormDialog
            template={this.renderVideo()}
            handleClose={this.closeVideo}
            open={this.state.playVideo}
            cover
            shrink
            center
          />
        )}
      </div>
    )
  }
}

export default injectSheet(styles)(Splash)
