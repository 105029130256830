import React, { useRef, useState } from "react";
import axios from "axios";
import injectSheet from 'react-jss';
import Button from "../Button/Button";

const styles = {
    registerWithEmail: {
        height: '48px',

        "& > div": {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: '14px',

            "& > button": {
                flex: '0 200px',
            }
        }
    },
    emailInput: {
        height: '100%',
        flex: '1 100%',
        backgroundColor: '#FFF',
        color: '#666',
        borderRadius: '4px',
        border: '1px solid gainsboro',
        padding: '12px 8px',
        boxSizing: 'border-box',
        fontSize: '14px'
    },
    registerMessage: {
        margin: '12px 0',
        color: '#666'
    }
}

const RegisterWithEmail = ({ classes: c }: { classes?: any }) => {
    const emailInput = useRef();
    const emailButton = useRef();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

  function emailRequest() {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
        setLoading(true);
      axios
        .post(
          "https://hooks.zapier.com/hooks/catch/1202079/oyrlwh0/",
          {
            email
          },
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then(res => {
            console.log(res)
          setLoading(false)
          setMessage('Request submitted!')
        })
        .catch(err => {
          console.log(err);
        });
    } else {
        setMessage('Email is not correct!')
    }
  }

    const handleEmailChange = () => {
        setEmail(emailInput.current.value)
    }
  
    return (
        <div className={c.registerWithEmail}>
            <div>
                <input
                    ref={emailInput}
                    type="text"
                    className={c.emailInput}
                    placeholder="Email"
                    onChange={handleEmailChange}
                />  
                <Button
                    ref={emailButton}
                    label={loading ? "Loading..." : "Create free account"}
                    onClick={emailRequest}
                />
            </div>
            <div className={c.registerMessage}>{ message }</div>
        </div>
    );
};

export default injectSheet(styles)(RegisterWithEmail);
