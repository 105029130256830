import * as React from 'react'
import injectSheet from 'react-jss'
import { Image } from 'cloudinary-react'
import { graphql, StaticQuery } from 'gatsby'
import HQTheme from '../../theme/theme'

const trustedCompaniesHeight = 160

interface TrustedCompaniesProps {
  classes?: any
  color: string
  hideOnDesktop?: boolean
  hideOnTablet?: boolean
  hideOnPhone?: boolean
  backgroundColor?: string
}

class TrustedCompanies extends React.Component<TrustedCompaniesProps> {
  renderPartners = (items: [], color: string) => {
    const { classes: c } = this.props
    return (
      <div className={c.trustedCompanies}>
        <div className={c.trustedCompaniesHeader}>
          <h2>Trusted by Professionals at</h2>
        </div>
        <div className={c.companyLogos}>
          {items.map(({ id, name, extension, small }) => (
            <div key={id}>
              <Image cloudName="hq-app" className={!!small ? '-small' : ''} secure publicId={`${name}-${color}.${extension}`} crop="scale" dpr="2.0" />
            </div>
          ))}
        </div>
      </div>
    )
  }
  render() {
    const { classes: c, color } = this.props
    return (
      <StaticQuery
        query={query}
        render={({ datoCmsHomePage: { trustedBy } }) => (
          <div className={c.trustedCompaniesWrapper}>{this.renderPartners(trustedBy, color)}</div>
        )}
      />
    )
  }
}

const query = graphql`
  query HomepageTrustedCompanies {
    datoCmsHomePage {
      trustedBy {
        id
        name
        extension
        small
      }
    }
  }
`

const styles = {
  trustedCompaniesWrapper: {
    height: `${trustedCompaniesHeight}px`,
    flex: `0 0 ${trustedCompaniesHeight}px`,
    backgroundColor: (props: any) => (props.backgroundColor ? props.backgroundColor : 'transparent'),
    display: 'flex',
    '@media (min-width: 1025px)': {
      display: (props: any) => props.hideOnDesktop && 'none',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
      margin: '16px',
      display: (props: any) => props.hideOnTablet && 'none',
    },
    '@media (max-width: 767px)': {
      display: (props: any) => props.hideOnPhone && 'none',
    },
  },
  trustedCompanies: {
    zIndex: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  trustedCompaniesHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > h2': {
      textAlign: 'center',
      color: HQTheme.palette.text.secondary,
      fontWeight: '700',
    },
  },
  companyLogos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: 'auto',
    height: 'auto',
    '@media (max-width: 1024px)': {
      flexWrap: 'wrap',
    },
    '& > div': {
      '@media (max-width: 1024px)': {
        width: 'calc(100% / 3)',
        textAlign: 'center',
      },
      '@media (max-width: 767px)': {
        width: 'calc(100% / 2)',
      },
      '& > img': {
        filter: 'hue-rotate(180deg) brightness(50%) contrast(90%)',
        height: '32px',
        '&.-small': {
          height: '24px',
        },
        '@media (max-width: 1024px)': {
          margin: '24px 0',
          height: '50px',
          color: 'black',
          marginBottom: '24px',
          '&.-small': {
            height: '30px',
          },
        },
        '@media (max-width: 767px)': {
          height: '40px',
          color: 'black',
          marginBottom: '24px',
          '&.-small': {
            height: '20px',
          },
        },
        '@media (max-width: 400px)': {
          height: '30px',
          '&.-small': {
            height: '16px',
          },
        },
      },
    },
  },
}

export default injectSheet(styles)(TrustedCompanies)
